<template>
  <div class="nav-menu-container">
    <div>
      <MenuItem
        v-for="menu in navMenu"
        v-bind:key="menu.title"
        :title="menu.title"
        :icon-class-name="menu.iconClassName"
        :isCollapse="isCollapse"
        :is-primary="false"
        :path="menu.path"
      />
    </div>
    <div>
      <a
        href="https://docs.byteark.com/th/stream/"
        rel="noreferrer nofollow"
        target="_blank"
      >
        <MenuItem
          title="Docs"
          iconClassName="fas fa-file-lines"
          :isPrimary="false"
          isExternalLink
        />
      </a>
      <MenuItem
        title="Manage"
        iconClassName="fas fa-screwdriver-wrench"
        :isCollapse="isCollapse"
        :path="{ name: 'geoBlocking' }"
      >
        <template v-slot:menu-item-suffix>
          <i class="fas fa-chevron-right" />
        </template>
      </MenuItem>
    </div>
  </div>
</template>

<script setup>
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';

defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const navMenu = [
  {
    title: 'Videos',
    iconClassName: 'fas fa-play-circle',
    path: { name: 'allProjectVideos' },
  },
  {
    title: 'Projects',
    iconClassName: 'fas fa-folder-open',
    path: { name: 'projects' },
  },
  // {
  //   title: 'Analytics',
  //   iconClassName: 'fas fa-chart-simple',
  // },
  // {
  //   title: 'Other Services',
  //   iconClassName: 'fas fa-grid',
  // },
];
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/breakpoint.scss';

.nav-menu-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-between;

  @media screen and (min-width: $min-layout-lg) {
    display: none;
  }
}
</style>
