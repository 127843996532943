<template>
  <div class="management-sidebar">
    <div class="back-button-container">
      <router-link :to="`/${teamName}/projects`">
        <i class="fa fa-chevron-left" /> {{$t('common:button.back')}}
      </router-link>
    </div>
    <h2 class="sidebar-title">Management</h2>
    <div class="sidebar-section-container">
      <div v-for="(sidebarItem, index) in sidebarItems" :key="index">
        <div class="sidebar-section " v-if="canShowSection(sidebarItem)">
          <div class="section-title" @click="onToggleCollapse(index)">
            <i :class="`section-icon fas ${sidebarItem.sectionIcon}`" />
            {{ sidebarItem.sectionTitle }}
            <i
              class="fas fa-chevron-down chevron-icon"
              :class="{ 'flip': !openCollapse[index].isCollapse }"/>
          </div>
          <div class="sub-items-container" v-if="!openCollapse[index].isCollapse">
            <div v-for="subItem in sidebarItem.subItems" :key="subItem.path">
              <router-link
                class="link"
                :to="`/${teamName}/manage/${subItem.path}`"
                v-if="canShowSubSection(subItem)">
                <div class="sub-item-title">{{subItem.title}}</div>
                <div class="description">{{subItem.description}}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-section project-manage-section">
        <router-link class="section-title" :to="`/${teamName}/manage/team-settings`">
          <i class="fas fa-gear icon-cog" />
          {{$t('teamSettings.header.title')}}
          <span :style="{ 'margin-left': 'auto'}">
            <Badge
              :title="$t('common:default')"
              badgeStyle="info"
              theme="dark"
              size="small" />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import { toRaw } from 'vue';
import { mapState } from 'vuex';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

export default {
  components: {
    Badge,
  },
  data() {
    return {
      openCollapse: [
        {
          isCollapse: false,
          sectionTitle: 'Secure & Protect',
        },
        {
          isCollapse: false,
          sectionTitle: 'Video Publishing',
        },
        {
          isCollapse: false,
          sectionTitle: 'Subtitle',
        },
        {
          isCollapse: false,
          sectionTitle: 'Video Output',
        },
        {
          isCollapse: false,
          sectionTitle: 'Video Information',
        },
        {
          isCollapse: false,
          sectionTitle: 'Import Settings',
        },
        {
          isCollapse: false,
          sectionTitle: 'Collection',
        },
        {
          isCollapse: false,
          sectionTitle: 'Plug-in',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      teamFeatures: (state) => state.teamFeatures.teamFeatures || {},
      currentUser: (state) => state.user.currentUser,
    }),
    teamName() {
      return this.$route.params.teamName;
    },
    currentPathName() {
      return this.$route.name;
    },
    sidebarItems() {
      return [
        {
          isCollapse: true,
          sectionTitle: 'Secure & Protect',
          sectionIcon: 'fa-shield-alt',
          key: 'secure',
          name: null,
          subItems: [
            {
              title: 'Geo-blocking Rules',
              description: this.$t('teamSettings.sidebar.geoblock.description'),
              path: 'geo-blocking',
            },
            {
              title: 'Allowed/Blocked Websites',
              description: this.$t('teamSettings.sidebar.accessControlPolicy.description'),
              path: 'allowed-blocked-website',
            },
            {
              title: 'Signed URL',
              description: this.$t('teamSettings.sidebar.signedUrl.description'),
              path: 'signed-url',
            },
            {
              title: 'ClearKey Encryption',
              description: this.$t('teamSettings.sidebar.clearKey.description'),
              path: 'clear-key',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Video Publishing',
          sectionIcon: 'fa-share-alt',
          key: 'video-publishing',
          name: null,
          subItems: [
            {
              title: 'Video Embed Players',
              description: this.$t('teamSettings.sidebar.player.description'),
              path: 'players',
            },
            {
              title: 'Playback Channels',
              description: this.$t('teamSettings.sidebar.playbackChannel.description'),
              path: 'playback-channels',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Bumper',
          sectionIcon: 'fa-play-circle',
          key: 'bumpers',
          permissionRoles: ['user', 'manager'],
          subItems: [
            {
              title: 'Bumpers',
              description: this.$t('teamSettings.sidebar.manageBumper.description'),
              path: 'bumpers',
            },
            {
              title: 'Bumper Insertion',
              description: this.$t('teamSettings.sidebar.applyBumper.description'),
              path: 'bumper-insertion',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Subtitle',
          sectionIcon: 'fa-share-alt',
          key: 'subtitle',
          name: null,
          subItems: [
            {
              title: 'Auto-generated Subtitle Profiles',
              description: this.$t('teamSettings.sidebar.subtitleprofile.description'),
              path: 'subtitle-profiles',
            },
            {
              title: 'Bad Word List',
              description: this.$t('teamSettings.sidebar.badwordlist.description'),
              path: 'badword-lists',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Video Output',
          sectionIcon: 'fa-video',
          key: 'video-output',
          name: null,
          subItems: [
            {
              title: 'Presets',
              description: this.$t('teamSettings.sidebar.preset.description'),
              path: 'presets',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Video Information',
          sectionIcon: 'fa-rectangle-list',
          key: 'video-information',
          name: null,
          subItems: [
            {
              title: 'Custom Fields',
              description: this.$t('project.manage.customFields.description'),
              path: 'custom-fields',
            },
            {
              title: 'Custom Image Fields',
              description: this.$t('project.manage.customImageFields.description'),
              path: 'custom-image-fields',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Import Settings',
          sectionIcon: 'fa-solid fa-file-import',
          key: 'import-settings',
          name: null,
          subItems: [
            {
              title: this.$t('project.manage.videoSources.title'),
              description: this.$t('project.manage.videoSources.description'),
              path: 'video-sources',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Collection',
          sectionIcon: 'fa-list',
          key: 'collection',
          name: null,
          subItems: [
            {
              title: 'Custom Collection Types',
              description: this.$t('project.manage.collectionType.description'),
              path: 'collection-type',
            },
          ],
        },
        {
          isCollapse: true,
          sectionTitle: 'Plug-in',
          sectionIcon: 'fa-plug rotate',
          key: 'plug-in',
          name: null,
          subItems: [
            {
              title: 'Uploader Forms',
              description: this.$t('teamSettings.sidebar.formUpload.description'),
              path: 'forms',
            },
          ],
        },
      ];
    },
  },
  created() {
    // const newSidebarItems = toRaw(this.sidebarItems).filter((item) => this.canAcesss(item.permissionRoles));
    this.openCollapse = this.sidebarItems.map((item) => ({
      isCollapse: false, // !this.isActive(item.key), ----- UNCOMMENT THIS AND SIDEBAR WILL COLLAPSE -----
      sectionTitle: item.sectionTitle,
    }));
  },
  methods: {
    // canAcesss(permissionRoles) {
    //   if (this.currentUser?.role === 'manager' || this.currentUser?.role === 'admin') {
    //     return true;
    //   }
    //   return permissionRoles?.includes(this.currentUser?.role);
    // },
    onToggleCollapse(index) {
      this.openCollapse[index].isCollapse = !this.openCollapse[index].isCollapse;
    },
    isActive(key) {
      if (key === 'secure') {
        return this.isGeoblockRulePathActive() || this.isAccessControlPolicyPath() || this.isSignedURLPath() || this.isClearKeyPathActive();
      }
      if (key === 'video-publishing') {
        return this.isPlayerSettingActive() || this.isPlaybackChannelPathActive();
      }
      if (key === 'playback-channels') {
        return this.isPlaybackChannelPathActive();
      }
      if (key === 'plug-in') {
        return this.isFormSettingPathActive();
      }
      if (key === 'clear-key') {
        return this.isClearKeyPathActive();
      }
      if (key === 'video-output') {
        return this.isPresetPathActive();
      }
      if (key === 'video-information') {
        return this.isCustomFieldsPathActive() || this.isCustomImageFieldsPathActive();
      }
      if (key === 'import-settings') {
        return this.isVideoSourcesPathActive();
      }
      if (key === 'bumpers') {
        return this.isBumperPathActive();
      }
      if (key === 'subtitle') {
        return this.isSubtitlePathActive();
      }
      if (key === 'collection') {
        return this.isCollectionTypePathActive();
      }
      return false;
    },
    isCollectionTypePathActive() {
      if (this.currentPathName === 'collectionType') {
        return true;
      }
      if (this.currentPathName === 'manageCollectionTypeDetails') {
        return true;
      }
      if (this.currentPathName === 'manageCollectionTypeImageDetails') {
        return true;
      }
      return false;
    },
    isPlaybackChannelPathActive() {
      if (this.currentPathName === 'playbackChannels') {
        return true;
      }
      if (this.currentPathName === 'playbackChannelDetail') {
        return true;
      }
      if (this.currentPathName === 'createPlaybackChannel') {
        return true;
      }
      return false;
    },
    isPresetPathActive() {
      if (this.currentPathName === 'presets') {
        return true;
      }

      if (this.currentPathName === 'presetCreate') {
        return true;
      }

      if (this.currentPathName === 'presetDetail') {
        return true;
      }

      return false;
    },
    isClearKeyPathActive() {
      if (this.currentPathName === 'clearKey') {
        return true;
      }
      if (this.currentPathName === 'keyServerEdit') {
        return true;
      }
      return false;
    },
    isPlayerSettingActive() {
      if (this.currentPathName === 'playerList') {
        return true;
      }
      if (this.currentPathName === 'playerEdit') {
        return true;
      }
      return false;
    },
    isFormSettingPathActive() {
      if (this.currentPathName === 'formList') {
        return true;
      }
      if (this.currentPathName === 'formEdit') {
        return true;
      }
      return false;
    },
    isGeoblockRulePathActive() {
      if (this.currentPathName === 'geoBlocking') {
        return true;
      }
      if (this.currentPathName === 'editGeoblockRule') {
        return true;
      }
      if (this.currentPathName === 'createGeoblockRule') {
        return true;
      }
      return false;
    },
    isAccessControlPolicyPath() {
      if (this.currentPathName === 'allowedBlockedWebsite') {
        return true;
      }
      return false;
    },
    isSignedURLPath() {
      if (this.currentPathName === 'signedUrl') {
        return true;
      }
      return false;
    },
    isCustomFieldsPathActive() {
      const { currentPathName } = this;
      switch (currentPathName) {
        case 'customFields':
        case 'customFieldsEdit':
        case 'customFieldsCreate':
        case 'customFieldsDetail':
          return true;
        default:
          return false;
      }
    },
    isCustomImageFieldsPathActive() {
      const { currentPathName } = this;
      switch (currentPathName) {
        case 'customImageFields':
        case 'createCustomImageFields':
        case 'editCustomImageFields':
        case 'deleteCustomImageFields':
          return true;
        default:
          return false;
      }
    },
    isVideoSourcesPathActive() {
      return this.currentPathName === 'videoSources';
    },
    isBumperPathActive() {
      if (this.currentPathName === 'manageBumper' || this.currentPathName === 'createBumper' || this.currentPathName === 'editBumper') {
        return true;
      }
      return this.isBumperInsertionPathActive();
    },
    isBumperInsertionPathActive() {
      return this.currentPathName === 'manageBumperDisplay' || this.currentPathName === 'createBumperDisplay' || this.currentPathName === 'bumperDisplayDetail' || this.currentPathName === 'editBumperDisplay';
    },
    isSubtitlePathActive() {
      return this.currentPathName === 'subtitleProfiles' || this.currentPathName === 'badwordLists' || this.currentPathName === 'badwordListsItemDetail';
    },
    isTeamFeature(key) {
      return this.teamFeatures && this.teamFeatures[key];
    },
    canShowSubSection(subSection) {
      if (subSection.path === 'custom-image-fields') {
        return this.isTeamFeature('custom-image');
      }

      if (subSection.path === 'subtitle-profiles') {
        return this.isTeamFeature('auto-generate-caption');
      }
      return true;
    },
    canShowSection(section) {
      if (section?.key === 'bumpers') {
        return this.isTeamFeature('bumper');
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.sidebar-title {
  padding: 12px 16px;
  margin-bottom: 0;
  color: $grey-800;

  @media screen and (max-width: $max-layout-md) {
    display: none;
  }
}

.back-button-container {
  a {
    color: $grey-500;
    transition: color 150ms ease;

    &:hover {
      color: $grey-800;
      text-decoration: none;
    }
  }
  font-size: 18px;
  padding: 16px 16px 4px;

  i {
    margin-right: 8px;
  }

  @media screen and (max-width: $max-layout-lg) {
    display: none;
  }
}

.management-sidebar {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #FBFBFC;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D0D4D8;
    border-radius: 3px;
  }

  .router-link-exact-active, .path-active {
    background-color: $bg-blue;
    color: $bg-navy;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background-color: $ci-primary;
    }
  }

  .sidebar-section-container {
    overflow: auto;
    flex: 1 1 auto;
  }

  .sidebar-section {
    padding-top: 18px;
    border-bottom: 1px solid #E8EAEE;

    .section-title {
      display: flex;
      align-items: center;
      font-size: $font-size-base * 1.2;
      padding-top: 0;
      padding-right: 20px;
      padding-bottom: 16px;
      padding-left: 20px;
      color: $grey-800;
      cursor: pointer;
      text-decoration: none;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $max-layout-xl) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .section-icon {
        font-size: 15px;
        margin-right: $spacing-base * 0.75;
      }
      .rotate {
        transform: rotate(45deg);
      }
      .chevron-icon {
        margin-left: auto;
        transition: all 0.2s ease-in-out;
        &.flip {
          transform: rotate(180deg);
        }
      }
    }

    .sub-items-container {
      animation: show 500ms ease;
      .link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        position: relative;
        padding-top: 8px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 48px;

        @media screen and (max-width: $max-layout-xl) {
          padding-left: 44px;
        }
      }
      .sub-item-title {
        color: $grey-600;
        font-weight: $font-weight-bold;
        font-size: $font-size-base * 1.1;
      }
      .description {
        color: $grey-500;
        font-size: $font-size-base;
      }
      @keyframes show {
        from {
          opacity: 0;
          max-height: 0;
        }
        to {
          opacity: 1;
          max-height: 100em;
        }
      }
    }
  }
  .single-section {
    position: relative;
    padding-top: 0;
    .section-title {
      padding-top: $spacing-base * 1.25;

      .icon-cog {
        margin-right: 0.75rem;
      }
    }
  }
}

.project-manage-section {
  position: relative;
  padding-top: 0 !important;

  a {
    padding-top: 18px !important;

    .fas {
      margin-right: $spacing-12;
    }
  }
}
</style>
